import { __identifier } from "~/stores/identifier.store";

import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(() => {
    if (__subscription().isTrialS3 && __identifier().getIdentifiers.length > 0) {
        log("❌ Trial S3 at max");
        return navigateTo("/");
    }

    log("✅ Not trial S3 at max");
});
